import React, { useState, useRef, useEffect } from "react";
import { ByteArkPlayerContainer } from "byteark-player-react";
import { useSelector, useDispatch } from "react-redux";
import {
  hidePopupVideoPlayer,
  setHidePopupVideoPlayerList,
  setEndedVideoPlayerList,
  updatePlaytime,
  updatePlaytimeLastWeek,
  updatePlaytimeLastWeekSelected,
} from "../redux/exerciseVideos";

function onPlayerReady(player, day_number, selectedVDO, setDuration, lastWeekStart, video_number) {
  const savedPlayTime = localStorage.getItem(
    `video-${day_number}-${video_number}-${lastWeekStart}-time`
  );

  if (!player.hlsjs) {
    return;
  }

  // ตั้งค่าระดับความละเอียด
  player.hlsjs.currentLevel = 4;

  // ตรวจสอบและเซ็ตเวลาเล่นต่อจากที่บันทึกไว้
  if (savedPlayTime && !isNaN(savedPlayTime) && savedPlayTime > 0) {
    player.currentTime(savedPlayTime);
  }

  // เมื่อวิดีโอโหลดเสร็จแล้ว ให้ตั้งค่า duration และ autoplay
  player.on("loadedmetadata", function () {
    setDuration(player.duration());

  });
}

function onPlayerCreated(player, playerRef, setCurrentTime, setDuration, day_number, selectedVDO, lastWeekStart, video_number) {
  playerRef.current = player;
  player.on("ready", function () {
    onPlayerReady(player, day_number, selectedVDO, setDuration, lastWeekStart, video_number);
  });

  player.on("timeupdate", function () {
    const currentTime = player.currentTime();
    setCurrentTime(currentTime);
    localStorage.setItem(`video-${day_number}-${video_number}-${lastWeekStart}-time`, currentTime);
  });
}

const VideoPlayerListByteArkBackUp = ({
  url,
  day_number,
  video_number,
  selectedVDO,
  lastWeekVDO_click,
  lastWeekVDOAll,
  lastWeekStart,
  selectExerciseVideoLastWeek,
  isCurrentWeek,
  imageVideoUrl,
  autoPlayCheck
}) => {
  const dispatch = useDispatch();
  const videoRef = useRef(null); // Video element reference
  const playerRef = useRef(null); // Video.js player reference
  const exerciseVideo = useSelector((state) => state.exerciseVideos.exerciseVideo);
  const user = useSelector((state) => state.authUser.user);
  const all_exercise_activity = useSelector((state) => state.exerciseVideos.all_exercise_activity);



  const [options, setOptions] = useState({
    fluid: true,
    autoplay: true,
    aspectRatio: "16:9",
    poster: imageVideoUrl,
    sources: {
      src: selectedVDO?.url || "",
      type: "application/x-mpegURL"
    },
  });

  const [isVideoVisible, setIsVideoVisible] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [videoEnded, setVideoEnded] = useState(false);

  // เมื่อ selectedVDO เปลี่ยน กำหนด player ใหม่
  useEffect(() => {


    if (selectedVDO) {

      dispatch(setEndedVideoPlayerList(false));
      setOptions((prevOptions) => ({
        ...prevOptions,
        poster: selectedVDO.poster || imageVideoUrl || prevOptions.poster,
        sources: {
          src: selectedVDO.url,
          type: "application/x-mpegURL"
        },
      }));
    }

    setDuration(selectedVDO.duration);


  }, [selectedVDO, imageVideoUrl, day_number, video_number]);

  useEffect(() => {
    if (currentTime / duration >= 0.999) {
      localStorage.setItem(`video-${day_number}-${video_number}-${lastWeekStart}-time`, 0);
      setVideoEnded(true);

    }



    if (currentTime / duration >= 0.85) {
      updatePlayTime();
    }

  }, [currentTime]);


  const updatePlayTime = () => {

    const tempExerciseVideoLastWeekSelect = [...selectExerciseVideoLastWeek];
    const tempExerciseVideoLastWeekAll = [...all_exercise_activity];
    tempExerciseVideoLastWeekSelect[day_number][video_number] = {
      ...tempExerciseVideoLastWeekSelect[day_number][video_number],
      play_time: duration,
      duration: duration,
    };




    /* if (!isCurrentWeek) { */ // เเบบเดิม  //updatePlayTime ของผู้ใช้ต่ออายุดูย้อนหลัง

    if (tempExerciseVideoLastWeekAll.length != lastWeekStart) {

      //updatePlayTime ของผู้ week ย้อนหลัง


      dispatch(
        updatePlaytimeLastWeekSelected(
          user.user_id,
          user.start_date,
          user.expire_date,
          day_number,
          video_number,
          duration,
          duration,
          tempExerciseVideoLastWeekAll,
          lastWeekStart
        )
      );
    } else {


      //updatePlayTime ของผู้ใช้ต่ออายุดูคลิปปัจจุบัน ไม่ได้ใช้เเล้ว
      // updatePlayTime ของผู้ week ปัจจุบัน 
      const tempExerciseVideo = [...exerciseVideo];
      tempExerciseVideo[day_number][video_number] = {
        ...tempExerciseVideo[day_number][video_number],
        play_time: duration,
        duration: duration,
      };


      dispatch(
        updatePlaytime(
          user.user_id,
          user.start_date,
          user.expire_date,
          day_number,
          video_number,
          duration,
          duration,
          tempExerciseVideo
        )
      );
    }
  };



  useEffect(() => {


    if (videoEnded && autoPlayCheck) {
      dispatch(setEndedVideoPlayerList(true));

    }
  }, [videoEnded]);

  const handleVideoClose = () => {
    /*     if (playerRef.current) {
          playerRef.current.pause();
          playerRef.current.dispose();
          playerRef.current = null;
        }
        setIsVideoVisible(false); */

    if (autoPlayCheck) {
      dispatch(setHidePopupVideoPlayerList(true));
    } else {
      dispatch(hidePopupVideoPlayer(true));
    }
  };

  const startPlayNew = () => {
    // Reset the saved playtime in local storage
    localStorage.setItem(`video-${day_number}-${video_number}-${lastWeekStart}-time`, 0);

    if (playerRef.current) {  // Use playerRef.current instead of player
      playerRef.current.currentTime(0);  // Reset the playtime to 0
      playerRef.current.play();  // Start playback
    }
  };

  return (
    <div className="container" style={{ maxWidth: "800px" }}>
      <div className="row">
        <div className="trailer-video-hal-ak">
          {isVideoVisible && (
            <section className="mt-4 mb-4">
              <ByteArkPlayerContainer
                className="mt-4 mb-4"
                onPlayerCreated={(player) =>
                  onPlayerCreated(player, playerRef, setCurrentTime, setDuration, day_number, selectedVDO, lastWeekStart, video_number)
                }
                {...options}
              />
            </section>
          )}
          <div style={{ display: "flex", position: "absolute", bottom: "-60px", left: "24px" }}>
            <button type="button" class="box-random" onClick={() => startPlayNew()}>เริ่มใหม่</button>

          </div>
        </div>

      </div>
      <img
        alt="close"
        src="../assets/img/thumb/close.png"
        className="close"
        onClick={handleVideoClose}
      />
    </div>
  );
};

export default VideoPlayerListByteArkBackUp;
