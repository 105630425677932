import React, { Component } from "react";
import { CardTitle, Form, Label, Input, Button, Spinner } from "reactstrap";
import { connect } from "react-redux";
import Bg_Login from "../assets/img/bg_login.png";
import Bg_Login_Head from "../assets/img/head.png";
import Banner_login from "../assets/img/banner_login.png";

import { loginUser, loginUserTimeOut } from "../redux/auth";
import "./login.scss";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      statusLogin: "default",
      statusReason: null,
      message500: null,
      submitLogin: false
    };
  }

  componentDidMount() {
    const { user } = this.props;
    if (user !== null) {
      this.props.history.push("/videolist");
    }
  }

  componentDidUpdate(prevProps) {
    const { status, statusLogin, statusLoginReason } = this.props;

    if (prevProps.status !== status) {
      if (this.props.user) {
        if (this.props.user.authorization === "admin") {
          this.props.history.push("/import-members");
        } else {
          this.props.history.push("/videolist");
        }
      }
      if (
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email) &&
        status === "fail"
      ) {
        this.setState({
          statusLogin: "fail",
        });
      }
    }


    if (this.props.status === 'default' && prevProps.status !== 'loading' && this.state.submitLogin && this.props.status !== "fail") {
      // ตั้งค่า setTimeout เพื่อเรียก loginUserTimeOut หลังจาก 1 นาที (60000 มิลลิวินาที)

      this.timeoutId = setTimeout(() => {
        this.setState({ message500: "กรุณาล๊อกอินใหม่" });
        this.props.loginUserTimeOut();

      }, 20000);
    } else if (this.props.status !== 'loading' && prevProps.status === 'loading') {
      // ถ้า this.props.status เปลี่ยนจาก 'fail' เป็นค่าอื่น ล้าง setTimeout ที่ตั้งไว้
      this.setState({ message500: null })
      this.setState({ submitLogin: false })
      clearTimeout(this.timeoutId);
    }


    if (prevProps.statusLogin === 'loading' && statusLogin === 'fail_date') {

      this.setState({
        statusLogin: "fail_date",
        statusReason: statusLoginReason
      });
    }



  }

  componentWillUnmount() {
    this.setState({ message500: null })
    this.setState({ submitLogin: false })
    this.setState({ statusReason: null })
    // ล้าง setTimeout ถ้าคอมโพเนนต์ถูกยกเลิก (unmount)
    clearTimeout(this.timeoutId);
  }

  onUserLogin(e) {

    this.setState({ submitLogin: true })
    e.preventDefault();
    if (!!this.props.user == false && this.props.status === "fail") {
      this.setState({
        statusLogin: "fail",
      });
    }
    if (this.state.email !== "") {
      this.props.loginUser(this.state.email, this.state.password);
      if (
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
      ) {
        this.setState({
          statusLogin: "fail",
        });
      }
    } else if (this.state.email === "") {
      this.setState({
        statusLogin: "fail",
      });
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  render() {
    const { statusLogin, statusReason } = this.state;
    console.log("statusLogin, statusReason", statusLogin, statusReason);

    return (
      <div
        className="all-row-login"
        style={{
          // backgroundImage: `url(${Bg_Login})`,
          backgroundColor: `#FCDEEC`,
        }}
      >
        <div className="row container">
          <div
            className="auth-card shadow col-lg-10 offset-lg-1 col-md-12 col-12"
            style={{ borderRadius: "16px", background: "#FFF8FB" }}
          >
            <div className="position-relative image-side-login col-lg-6 col-12">
              {/* <p className="welcome-program mt-3">ยินดีต้อนรับเข้าสู่โปรแกรม</p> */}
              <img className="mb-4 col-12" src={Bg_Login_Head} alt="" />
            </div>

            <div className="form-side-login col-lg-6 col-12 w-100 d-flex flex-column align-items-center justify-content-center">
              <CardTitle className="h3 mb-4 mt-5">
                <b>เข้าสู่ระบบ</b>
              </CardTitle>
              <Form onSubmit={(e) => this.onUserLogin(e)} className="w-100">
                <Label className="form-group2 has-float-label mb-4">
                  {"อีเมลเข้าใช้งาน"}
                  <span style={{ color: "red" }}>*</span>
                  <Input
                    type="email"
                    id="email"
                    value={this.state.email}
                    onChange={(event) => this.handleChange(event)}
                    className="py-4"
                    placeholder="example@mail.com"
                  />
                </Label>
                {/*  {statusLogin === "fail" &&
                  !(this.props.status === "success") && (
                    <small id="emailHelp" className="form-text text-muted mb-3">
                      <h6 style={{ color: "red" }}>อีเมลไม่ถูกต้อง</h6>
                    </small>
                  )} */}
                {statusLogin == "fail_date" && statusReason != null &&
                  (
                    <small id="emailHelp" className="form-text text-muted mb-3">
                      <h6 style={{ color: "red" }}>{statusReason}</h6>
                    </small>
                  )}
                {(this.state.message500 && this.state.submitLogin && statusLogin !== "fail" && statusLogin !== "fail_date") &&

                  <small id="emailHelp" className="form-text text-muted mb-3">
                    <h6 style={{ color: "red" }}>{this.state.message500}</h6>
                  </small>
                }

                {this.props.statusLogin == "loading" ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner
                      style={{
                        color: "#ED0876",
                      }}
                    />
                  </div>
                ) : (
                  <div className="d-flex justify-content-between align-items-center mb-3 btn-login">
                    <Button
                      style={{
                        backgroundColor: "#F45197",
                        borderColor: "#F45197",
                        borderRadius: "20px",
                      }}
                      className="btn-shadow"
                      size="lg"
                      type="submit"
                      block
                    >
                      <span className="h6 text-one">{"ดำเนินการต่อ"}</span>
                    </Button>
                  </div>
                )}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <Button
                    className="btn-link"
                    color="empty"
                    href="https://bebehiit.pynk.co"
                    target="_blank"
                    block
                  >
                    <span
                      className="h6 text-one underline-on-hover"
                      style={{ cursor: "pointer", color: "#F45197" }}
                    >
                      {"ซื้อคอร์สออนไลน์"}
                    </span>
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ authUser }) => {
  const { user, status, statusLogin, statusLoginReason } = authUser;
  return { user, status, statusLogin, statusLoginReason };
};

const mapActionsToProps = { loginUser, loginUserTimeOut };

export default connect(mapStateToProps, mapActionsToProps)(Login);
